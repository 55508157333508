// React
import React from "react"

// Third party
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { IconContext } from "react-icons"
import { FiPhoneCall } from "react-icons/fi"

// file
import BackImage from "../images/backgroundBlack.png"
import LogoImage from "../images/logoWhite.svg"
import { media } from "../styles/media"

// component
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const IconCall = () => (
  <IconContext.Provider value={{ color: "#292929", size: "20px" }}>
    <FiPhoneCall />
  </IconContext.Provider>
)

const StyledWrap = styled.div`
  width: 100%;
  position: relative;
`
const StyledBack = styled.div`
  position: absolute;
  background: url(${BackImage});
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`
const StyledContents = styled.div`
  ${() =>
    media.tablet(css`
      max-width: 800px;
    `)}

  z-index:1;
  position: relative;
  padding: 30px 0;
  width: 90%;
  margin: 0 auto;
  h2 {
    width: 100%;
    text-align: center;
    font-size: 1.62em;
  }
  .logoImage {
    width: 300px;
    height: 200px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    border: 2px solid white;
    background: black;
    img {
      width: 80%;
    }
  }
`

const StyledBlock = styled.div`
  padding: 30px 0 50px;
  p {
    line-height: 1.62em;
    padding-bottom: 1.62em;
  }
  dl {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    dt {
      width: 20%;
    }
    dd {
      width: 80%;
    }
  }
  ${() =>
    media.tablet(css`
      padding: 50px 20%;
      .gatsby-image-wrapper {
        width: 80%;
        margin: 0 auto;
      }
    `)}
`

const StyledMap = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  iframe {
    width: 100%;
    height: 300px;
    ${() =>
    media.tablet(css`
        width: 800px;
      `)}
  }
`
const StyledCall = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 0.62em 1.62em;
    box-shadow: 5px 5px 0px #acacac;
    color: black;
    svg {
      margin-right: 0.62em;
    }
  }
`


const AboutUs = () => {
  const data = useStaticQuery(query)

  const jsonld = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": `${data.seo.pagesSeo[2].pageTitle}`,
      "description": `${data.seo.pagesSeo[2].pageDescript}`,
      "publisher": {
        "@type": "ProfilePage",
        "name": `${data.seo.organizeName}`
      }
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.pagesSeo[2].pageUrl}`,
          "name": `${data.seo.pagesSeo[2].pageTitle}`,
          "image": `${data.seo.pagesSeo[2].pageMedia.absolutePath}`
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[2].pageTitle}
        description={data.seo.pagesSeo[2].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[2].pageUrl}
        type='article'
        imageUrl={data.seo.pagesSeo[2].pageMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack></StyledBack>
        <StyledContents>
          <h2>{data.zoo.pageTitle}</h2>
          <div className="logoImage">
            <img src={LogoImage} alt="アリンタロイヤルリゾート" />
          </div>
          <StyledBlock>
            <p
              className="afterLogo"
              dangerouslySetInnerHTML={{ __html: data.zoo.dsiText }}
            />
            <Image
              fluid={data.zoo.pageMedia.childImageSharp.fluid}
              alt={data.zoo.pageMediaAlt}
            />
            <dl>
              <dt>住所</dt>
              <dd>{data.zoo.addresTxt}</dd>
            </dl>
            <dl>
              <dt>電話</dt>
              <dd>
                <a href={"tel:" + data.zoo.telTxt}>{data.zoo.telTxt}</a>
              </dd>
            </dl>
          </StyledBlock>
          <StyledCall>
            <Link to="/inquiry/">
              <IconCall />
              お問合せ・ご予約
            </Link>
          </StyledCall>
          <StyledMap>
            <div dangerouslySetInnerHTML={{ __html: data.bota.mapIframe }} />
          </StyledMap>
        </StyledContents>
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiAboutUsPage {
      pageTitle
      dsiText
      pageMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pageMediaAlt
      telTxt
      addresTxt
    }
    bota: strapiIndexMemo {
      mapIframe
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default AboutUs
